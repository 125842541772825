import { section as sectionData } from "scmp-app/data";

import type { Section } from "./types";

export const SubSectionList = ["luxury", "fashion", "beauty", "people", "lifestyle"] as const;
export type SubSection = (typeof SubSectionList)[number];

export const MaximumArticleNumberPerSection = 4;
export const MaximumArticleCardNumber = MaximumArticleNumberPerSection * SubSectionList.length;

export const SectionNameMap: Record<SubSection, Section> = {
  beauty: {
    ...sectionData.style.beauty,
    pathname: "beauty",
  },
  fashion: {
    ...sectionData.style.fashion,
    pathname: "fashion",
  },
  lifestyle: {
    ...sectionData.style.lifestyle,
    pathname: "lifestyle",
  },
  luxury: {
    ...sectionData.style.luxury,
    pathname: "luxury",
  },
  people: {
    ...sectionData.style.people,
    pathname: "people",
  },
};
