/**
 * @generated SignedSource<<d66b6e30874c5155d6748e18d13fee52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetArticleTypeArticle$data = {
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetArticleTypeArticle";
};
export type helpersGetArticleTypeArticle$key = {
  readonly " $data"?: helpersGetArticleTypeArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleTypeArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetArticleTypeArticle"
};

(node as any).hash = "3c58fbc79ba52ae9ebf1978fc8166cec";

export default node;
