import { UnsupportedBehavior, useIdleCallback } from "@shopify/react-idle";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useState } from "react";

type Props = {
  children?: ReactNode;
  unsupportedBehavior?: UnsupportedBehavior;
};

export const IdleQueue: FunctionComponent<Props> = ({
  children,
  unsupportedBehavior = UnsupportedBehavior.Immediate,
}) => {
  const [isIdle, setIsIdle] = useState(false);
  const onIdleCallback = useCallback(() => {
    setIsIdle(true);
  }, []);
  useIdleCallback(onIdleCallback, {
    unsupportedBehavior,
  });
  if (!isIdle) return null;
  return <>{children}</>;
};

IdleQueue.displayName = "IdleQueue";
