export const pathname = "/plus/search";

export const page = {
  route(this: void, query: QueryParameters<{ nodeId?: string; value?: string }>) {
    return {
      asPath: `${pathname}/${query.value ?? ""}`,
      pathname,
      query: { ...query, pageType: "search" },
    };
  },
};
