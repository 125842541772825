import type { ScmpUser } from "@product/scmp-account-sdk";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import qs from "qs";

import { config } from "scmp-app/data";

import type { ScmpPianoJWT } from "./atoms";
import {
  SCMPAccessTokenExpireCookiesKey,
  SCMPPianoAccessTokenCookiesKey,
  SCMPUserCookiesKey,
  SCMPUserLoginTypeCookiesKey,
} from "./consts";

export const getAccountState = () => {
  const state = {
    accessTokenExpiryTime: (() => {
      const raw = Cookies.get(SCMPAccessTokenExpireCookiesKey);
      return raw ? Number.parseInt(raw) : null;
    })(),
    loginType: Cookies.get(SCMPUserLoginTypeCookiesKey),
    pianoAccessToken: Cookies.get(SCMPPianoAccessTokenCookiesKey),
    pianoUser: (() => {
      const raw = Cookies.get(SCMPPianoAccessTokenCookiesKey);
      return raw ? jwtDecode<ScmpPianoJWT>(raw) : null;
    })(),
    user: (() => {
      const raw = Cookies.get(SCMPUserCookiesKey);
      if (!raw) return null;
      const parsed = JSON.parse(raw) as ScmpUser;
      return {
        ...parsed,
        created: Number.parseInt(`${parsed.created}`, 10),
        crs: Number.parseInt(`${parsed.crs}`, 10),
      };
    })(),
  };

  const isLoggedIn = !!state.user;
  return {
    ...state,
    isLoggedIn,
  };
};

export function getLoginUrl(destination?: string | undefined) {
  const host = location.origin;

  return `${config.account.scmpAccountHost}/login?callback_url_signin=${encodeURIComponent(
    `${host}/centralize/signin`,
  )}&callback_url_register=${encodeURIComponent(
    `${host}/centralize/register`,
  )}&destination=${encodeURIComponent(destination ?? window.location.href)}`;
}

export function getGoogleLoginUrl(parameters: Record<string, string>) {
  const host = location.origin;

  const query = qs.stringify(parameters);

  return `${config.account.scmpAccountHost}/login/google?callback=${encodeURIComponent(
    `${host}/centralize/signin`,
  )}&destination=${encodeURIComponent(`${window.location.href}?${query}`)}&${query}`;
}

export function getLogoutUrl(destination?: string | undefined) {
  return `${config.account.scmpAccountHost}/logout?destination=${encodeURIComponent(
    destination ?? window.location.href,
  )}`;
}

export const maskValue = (value: string) =>
  [value.slice(0, 2), "*".repeat(value.slice(2, -5).length), value.slice(-5)].join("");
