import type {
  MeterData,
  RosettaSDK,
  RosettaUser,
  Tracking,
  v2Outcomes,
} from "@product/rosetta-sdk";
import type { AsyncState } from "@react-hookz/web";
import { atom } from "jotai";

export type AppSettings = {
  isShowPremiumPromo?: boolean;
  mixpanelStorage?: string;
  oldArticleTimeframe?: number;
  paywallCampaignName?: string;
};

export type Rosetta = {
  appSettings?: AppSettings;
  contentServiceUserInfo?: {
    isShowVelocityWall: boolean;
    isSubscriptionEnabled: boolean;
  };
  hasIpAccess?: boolean;
  hasPostiesAccessRight?: boolean;
  hasScmpPlusAccessRight?: boolean;
  instance?: RosettaSDK;
  isPostiesSubscriber?: boolean;
  isScmpPlusSubscriber?: boolean;
  isScmpSubscriber?: boolean;
  meterData?: MeterData;
  posties?: {
    disablePaywall?: boolean;
  };
  tracking?: Tracking;
  user?: RosettaUser;
  v2?: v2Outcomes;
};

export const rosettaAtom = atom<AsyncState<Rosetta | undefined> | undefined>(undefined);
