export const pathname = "/search";

export type RouteFunction = (query: QueryParameters<{ value?: string }>) => {
  asPath: string;
  pathname: string;
  query: { pageType: string; value?: string };
};

export const page = {
  route(this: void, query: QueryParameters<{ value?: string }>) {
    return {
      asPath: `${pathname}/${query.value ?? ""}`,
      pathname,
      query: { ...query, pageType: "search" },
    };
  },
};
