import { findFirstNonEmptyString } from "@product/scmp-sdk";
import { type FunctionComponent, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import type { DeepNonNullable } from "utility-types";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import type { trendingNavBarSwiperQueueQuery } from "scmp-app/queries/__generated__/trendingNavBarSwiperQueueQuery.graphql";

import { Divider, Item, StyledSwiper, StyledSwiperSlide } from "./styles";
type Props = {
  className?: string;
  edition: string;
};

export const TrendingNavBarSwiper: FunctionComponent<Props> = ({ className, edition }) => {
  const data = useLazyLoadQuery<trendingNavBarSwiperQueueQuery>(
    graphql`
      query trendingNavBarSwiperQueueQuery(
        $trendingNavBarTopicSectionQueueName: String!
        $trendingNavBarArticleQueueName: String!
      ) {
        trendingNavBarArticleQueue: queue(filter: { name: $trendingNavBarArticleQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Content {
                  entityId
                  headline
                  socialHeadline
                  ...entityLink
                }
              }
            }
          }
        }
        trendingNavBarTopicSectionQueue: queue(
          filter: { name: $trendingNavBarTopicSectionQueueName }
        ) {
          items(first: 5) {
            edges {
              node {
                ... on Topic {
                  entityId
                  name
                  shortName
                  ...entityLink
                }
                ... on Section {
                  entityId
                  name
                  ...entityLink
                }
              }
            }
          }
        }
      }
    `,
    {
      trendingNavBarArticleQueueName: `homepage_subnav_series_${edition}`,
      trendingNavBarTopicSectionQueueName: `homepage_subnav_${edition}`,
    },
    { fetchPolicy: "store-or-network" },
  );

  const queueItems = useMemo(() => {
    type QueueItem = {
      entityId: string;
      name: string;
      node:
        | DeepNonNullable<
            typeof data
          >["trendingNavBarArticleQueue"]["items"]["edges"][number]["node"]
        | DeepNonNullable<
            typeof data
          >["trendingNavBarTopicSectionQueue"]["items"]["edges"][number]["node"];
    };
    const articles = (data.trendingNavBarArticleQueue?.items?.edges?.map?.(({ node }) => ({
      entityId: node.entityId,
      name: findFirstNonEmptyString(node.socialHeadline, node.headline),
      node,
    })) ?? []) as QueueItem[];
    const topicAndSections = (data.trendingNavBarTopicSectionQueue?.items?.edges?.map?.(
      ({ node }) => ({
        entityId: node.entityId,
        name: findFirstNonEmptyString(node.shortName, node.name),
        node,
      }),
    ) ?? []) as QueueItem[];

    return [...articles, ...topicAndSections].slice(0, 5);
  }, [
    data.trendingNavBarArticleQueue?.items?.edges,
    data.trendingNavBarTopicSectionQueue?.items?.edges,
  ]);

  if (queueItems.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: `oneline_menu_trending_${edition}`,
      }}
    >
      <StyledSwiper
        className={className}
        cssMode={false}
        pagination={false}
        slidesOffsetAfter={20}
        slidesPerView="auto"
      >
        {queueItems.map((queueItem, index) => (
          <StyledSwiperSlide key={queueItem.entityId}>
            <Item anchorProps={{ title: queueItem.name }} reference={queueItem.node}>
              {queueItem.name}
            </Item>
            {index !== queueItems.length - 1 && <Divider>|</Divider>}
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </BaseLinkContextProvider>
  );
};

TrendingNavBarSwiper.displayName = "TrendingNavBarSwiper";
