/**
 * @generated SignedSource<<d597dfca7550a8460f0134bea4ddd323>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersSanitizedAuthorEntityIdsArticle$data = {
  readonly authors: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersSanitizedAuthorEntityIdsArticle";
};
export type helpersSanitizedAuthorEntityIdsArticle$key = {
  readonly " $data"?: helpersSanitizedAuthorEntityIdsArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSanitizedAuthorEntityIdsArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersSanitizedAuthorEntityIdsArticle"
};

(node as any).hash = "bace16cf648d3ea9bdcede90e48f3499";

export default node;
