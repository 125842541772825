/**
 * @generated SignedSource<<26e9cac4973e633805848f0c0b4de734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersSectionContent$data = {
  readonly fullSectionPath: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersSectionContent";
};
export type helpersSectionContent$key = {
  readonly " $data"?: helpersSectionContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSectionContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersSectionContent"
};

(node as any).hash = "5df23160f7b8f1bbdf37c24326a35f2b";

export default node;
