import { areArraysEqual } from "scmp-app/components/section/helpers";
import { section as sectionData } from "scmp-app/data";

import { StyleMainSectionPath } from "./const";

export const isStyleSectionContent = (entityIds: string[]) => {
  if (entityIds.length < 2) return false;
  const [first, second] = entityIds;
  return first === sectionData.magazine.entityId && second === sectionData.style.entityId;
};

export const isStyleMainSectionBySectionPath = (sectionPath: string[]) => {
  if (sectionPath.length !== 2) return false;
  return areArraysEqual(sectionPath, StyleMainSectionPath);
};
