import { PageType } from "@product/scmp-sdk";

export const pathname = "/live";

export const page = {
  route(query: QueryParameters) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Live },
    };
  },
};
