import { config, data } from "scmp-app/data";

export const getAssetPrefix = () => {
  if (config.general.cdn.enabled) {
    return `https://${config.general.cdn.host}/${config.general.env}`;
  }
  return "";
};

export function isScmpDomain(host: string) {
  return Object.values(data.scmpDomains).some(domain => host.endsWith(domain));
}

export function getAbsoluteUrl(path?: string, domain = config.general.siteUrl) {
  if (!path) return domain;
  try {
    return new URL(path, domain).href;
  } catch {
    return domain;
  }
}
