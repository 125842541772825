import { css } from "@emotion/react";
import { theme } from "@product/scmp-sdk";
import mapValues from "lodash/mapValues";
import merge from "lodash/merge";

import type { ResponsiveVariants } from "./responsive";
import { setResponsiveVariantStyles } from "./responsive";

const cssVariableKeys = {
  appBarHeight: "--app-bar-height",
  contentAlignmentPaddingInline: "--content-alignment-padding-inline",
  headerAlignmentPaddingInline: "--header-alignment-padding-inline",
  pageAlignmentPaddingInline: "--page-alignment-padding-inline",
  pageMaxWidth: "--page-max-width",
} as const;

export const cssVariableValues = mapValues(cssVariableKeys, mapCssVariableKeyToValue);

type CSSVariableValue<CSSVariableKey extends string> = `var(${CSSVariableKey})`;
function mapCssVariableKeyToValue<T extends string>(key: T): CSSVariableValue<T> {
  return `var(${key})`;
}

export const setAlignmentVariables = (
  contentResponsivePadding?: Partial<ResponsiveVariants<number>>,
  pageResponsivePadding?: Partial<ResponsiveVariants<number>>,
) => css`
  /* stylelint-disable csstree/validator */
  ${cssVariableKeys.appBarHeight}: 60px;
  ${cssVariableKeys.pageMaxWidth}: 1560px;
  ${cssVariableKeys.headerAlignmentPaddingInline}: 0;

  ${theme.breakpoints.up("tablet")} {
    ${cssVariableKeys.headerAlignmentPaddingInline}: 24px;
  }

  ${theme.breakpoints.up("desktop")} {
    ${cssVariableKeys.headerAlignmentPaddingInline}: 12px;
  }

  ${setResponsiveVariantStyles(
    merge({ desktopUp: 8, mobileUp: 4, tabletUp: 8 }, contentResponsivePadding),
    variant => css`
      ${cssVariableKeys.contentAlignmentPaddingInline}: ${`${variant}px`};
    `,
  )} /* stylelint-enable csstree/validator */

  ${setResponsiveVariantStyles(
    merge({ desktopUp: 28, mobileUp: 16, tabletUp: 28 }, pageResponsivePadding),
    variant => css`
      ${cssVariableKeys.pageAlignmentPaddingInline}: ${`${variant}px`};
    `,
  )}
`;

// This expands the elements to 100vw
export const setFullViewportWidthStyles = () => css`
  position: relative;
  inset-inline: 50% 50%;

  inline-size: 100vw;
  margin-inline: -50vw;
`;

export const setFullViewportWidthBackgroundStyles = (backgroundColor: string) => css`
  ${setFullViewportWidthStyles()}
  position: absolute;
  inset-block-start: 0;
  z-index: -1;

  block-size: 100%;

  background-color: ${backgroundColor};
`;

// This value is for elements inside full viewport width container to match content alignment
export const fullViewportWidthWithMaxPageWidthPaddingInline = `calc(${cssVariableValues.pageAlignmentPaddingInline} +
          ${cssVariableValues.contentAlignmentPaddingInline})`;

// This expands the elements under App.PageContainer to match the full viewport width with max at page width
export const setFullViewportWidthWithMaxPageWidthStyles = () => css`
  inline-size: 100vw;
  max-inline-size: ${cssVariableValues.pageMaxWidth};
  margin-inline: calc(0px - ${fullViewportWidthWithMaxPageWidthPaddingInline});
`;

// This expands the elements under App.PageContainer to match the content width
export const setFullContentWidthStyles = () => css`
  margin-inline: calc(0px - ${cssVariableValues.contentAlignmentPaddingInline});
`;

export const alignmentCssVariableValues = {
  ...cssVariableValues,
  fullViewportWidthWithMaxPageWidthPaddingInline,
};
