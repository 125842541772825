import { PageType } from "@product/scmp-sdk";

export const pathname = "/author";

export const page = {
  route(query: QueryParameters<{ entityId: string }>) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Author },
    };
  },
};
