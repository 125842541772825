export const pathname = "/newsletters";

export const page = {
  route(query: QueryParameters<{ entityId?: string }>) {
    return {
      pathname,
      query: { ...query, pageType: "newsletter" },
    };
  },
};
