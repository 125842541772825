/**
 * @generated SignedSource<<024164bc1bdb366144d9203f8d764e8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckArticleRenderTypeArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformArticle" | "helpersCheckIsPlusArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "helpersCheckArticleRenderTypeArticle";
};
export type helpersCheckArticleRenderTypeArticle$key = {
  readonly " $data"?: helpersCheckArticleRenderTypeArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleRenderTypeArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckArticleRenderTypeArticle"
};

(node as any).hash = "cf1bb70634c718a4bd669b1cf79f168d";

export default node;
