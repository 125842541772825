/**
 * @generated SignedSource<<86a9924df9bd9b4ab0ee5ada1acd9557>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckArticleIsInSectionsArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckArticleIsInSectionsArticle";
};
export type helpersCheckArticleIsInSectionsArticle$key = {
  readonly " $data"?: helpersCheckArticleIsInSectionsArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleIsInSectionsArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckArticleIsInSectionsArticle"
};

(node as any).hash = "b6315305861f256d9ba36a01f36dd156";

export default node;
