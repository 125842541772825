/**
 * @generated SignedSource<<9621d176ff48907e368d3049e6ae130e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsStyleArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsStyleArticle";
};
export type helpersCheckIsStyleArticle$key = {
  readonly " $data"?: helpersCheckIsStyleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsStyleArticle"
};

(node as any).hash = "67ab4ed74adc967c60838bed2eea211f";

export default node;
