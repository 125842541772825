type ExternalScriptProviders =
  | "ad-block"
  | "advertisement"
  | "google-one-tap-login"
  | "mixpanel"
  | "piano"
  | "tracking";
export function isExternalScriptsDisabled(provider: ExternalScriptProviders) {
  if (typeof window === "undefined") return false;
  const noExternalScripts = new URL(location.href).searchParams.get("no-external-scripts");
  if (noExternalScripts === null) return false;
  return noExternalScripts === "" || noExternalScripts?.includes(provider);
}
