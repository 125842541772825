export const pathname = "/print-article";

export const page = {
  prefix: ["print"],
  route(query: QueryParameters<{ entityId: string }>) {
    return {
      pathname,
      query: { ...query, pageType: "print-article" },
    };
  },
};
