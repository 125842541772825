import { PageType } from "@product/scmp-sdk";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import type { Application } from "scmp-app/components/app-initializer/helpers";
import type { Variant as SectionVariant } from "scmp-app/pages/section/enums";

export const pathname = "/section";

export const page = {
  route(
    query: QueryParameters<{
      appBarVariant?: AppBarVariant;
      application?: Application;
      entityId: string;
      name?: string;
      parentSection?: string;
      relatedSectionsQueueName?: string;
      sectionVariant?: SectionVariant;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Section },
    };
  },
};
