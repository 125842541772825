import type { FunctionComponent } from "react";

import { isExternalScriptsDisabled } from "scmp-app/lib/utils";

import { AmazonPublisherServiceScripts } from "./amazon-publisher-service";
import { GoogleIMAScripts } from "./google-ima";
import { GooglePublisherTagScripts } from "./google-publisher-tag";
import { MagniteDemandManagerScripts } from "./magnite-demand-manager";

export const AdvertisementScripts: FunctionComponent = () =>
  isExternalScriptsDisabled("advertisement") ? null : (
    <>
      <AmazonPublisherServiceScripts />
      <GooglePublisherTagScripts />
      <MagniteDemandManagerScripts />
      <GoogleIMAScripts />
    </>
  );

AdvertisementScripts.displayName = "AdvertisementScripts";
