/**
 * @generated SignedSource<<42533c8be0f456a103ba9412a6e3976a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPlusPage$data = {
  readonly urlAlias: string;
  readonly " $fragmentType": "helpersCheckIsPlusPage";
};
export type helpersCheckIsPlusPage$key = {
  readonly " $data"?: helpersCheckIsPlusPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusPage">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPlusPage"
};

(node as any).hash = "02a31651300a73ceff70bbd66e4d9a4f";

export default node;
