import { PageType } from "@product/scmp-sdk";

export const pathname = "/topics";

export const page = {
  route(this: void, query: QueryParameters<{ char?: string }>) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Topics },
    };
  },
};
