import { useMountEffect } from "@react-hookz/web";
import * as Sentry from "@sentry/browser";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { config } from "scmp-app/data";
import { accountAtom } from "scmp-app/lib/account/atoms";

import { config as commonSentryConfig } from ".";
import { supportedBrowsers } from "./supported-browsers";

export const useSentrySetup = () => {
  const { user } = useAtomValue(accountAtom);
  useMountEffect(() => {
    Sentry.init({
      ...commonSentryConfig,
      beforeSend: event => {
        // Filter out those browser is not in our supported list
        if (typeof navigator !== "undefined" && !supportedBrowsers.test(navigator.userAgent))
          return null;
        return event;
      },
      integrations: [
        Sentry.extraErrorDataIntegration({ depth: 6 }),
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
      ],
      tracesSampleRate: config.sentry.enableTracing ? 0.1 : 0,
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    });
  });

  useEffect(() => {
    if (!user) return;
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      ...user,
    });
    scope.addEventProcessor(event => {
      // Avoid sending bunch of events if the user is not from internal
      // This is a workaround for changing the sample rate dynamically
      if (Math.random() < 0.01 || user.useremail.includes("@scmp.com")) return event;
      return null;
    });
  }, [user]);
};
