export const Article = "article";
export const Homepage = "homepage";
export const NotFound = "404";
export const ArticlePosties = "article_posties";
export const Brochure = "brochure";
export const Content = "content";
export const SectionPosties = "section_posties";
export const VideoPosties = "video_posties";
const PostiesPageTypes = [ArticlePosties, Brochure, Content, SectionPosties, VideoPosties] as const;

export type PostiesPageType = (typeof PostiesPageTypes)[number];
