/**
 * @generated SignedSource<<c0c88837514382d639c9a1a04eb559f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckCanShowInPlusPlatformArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleIsInSectionsArticle" | "helpersCheckIsPlusArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle" | "helpersUseContentSponsorContent">;
  readonly " $fragmentType": "helpersCheckCanShowInPlusPlatformArticle";
};
export type helpersCheckCanShowInPlusPlatformArticle$key = {
  readonly " $data"?: helpersCheckCanShowInPlusPlatformArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckCanShowInPlusPlatformArticle"
};

(node as any).hash = "a510a582704347c879336b002285cf96";

export default node;
