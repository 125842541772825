/**
 * @generated SignedSource<<c78de2c6163100cc6d3ddd0bf632c8a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPostiesArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsPostiesArticle";
};
export type helpersCheckIsPostiesArticle$key = {
  readonly " $data"?: helpersCheckIsPostiesArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostiesArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPostiesArticle"
};

(node as any).hash = "18e1e6159187c7d2a1d2181fb8a945c6";

export default node;
