import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { isStyleSectionContent } from "scmp-app/components/section/section-style/helpers";
import { Variant as SectionVariant } from "scmp-app/pages/section/enums";
import type { helpersSectionContent$key } from "scmp-app/queries/__generated__/helpersSectionContent.graphql";
import type { helpersStyleSectionContent$key } from "scmp-app/queries/__generated__/helpersStyleSectionContent.graphql";

import { isPostMagazineSectionContent } from "./section-post-magazine/helpers";

export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) return false;
  return array1.every((item, index) => item === array2[index]);
}

export const checkIsMagazineSectionContent = (reference: helpersStyleSectionContent$key) => {
  const section = readInlineData(
    graphql`
      fragment helpersStyleSectionContent on Section @inline {
        fullSectionPath {
          entityId
        }
      }
    `,
    reference,
  );

  const sectionEntityIds = section?.fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [];
  if (isStyleSectionContent(sectionEntityIds)) return SectionVariant.Style;
  if (isPostMagazineSectionContent(sectionEntityIds)) return SectionVariant.PostMagazine;
  return SectionVariant.Default;
};

export const checkIsSectionContent = (reference: helpersSectionContent$key) => {
  const section = readInlineData(
    graphql`
      fragment helpersSectionContent on Section @inline {
        fullSectionPath {
          entityId
        }
      }
    `,
    reference,
  );

  const sectionEntityIds = section?.fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [];
  return notEmpty(sectionEntityIds);
};
