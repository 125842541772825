import { safeSetLocalStorage } from "@product/scmp-sdk";
import { addMinutes } from "date-fns";
import { atom } from "jotai";
import Cookies from "js-cookie";
import type { JwtPayload } from "jwt-decode";

import { config } from "scmp-app/data";

import { RegisterEmailExpirationDateLocalStorageKey } from "./consts";
import { getAccountState, maskValue } from "./helpers";

export interface ScmpPianoJWT extends JwtPayload {
  email: string;
  first_name: string;
  last_name: string;
}

export const accountAtom = atom(getAccountState());

export interface RegisterEmailCookieAtom {
  expirationDate: null | string;
  registerEmail: null | string;
}

export const registerEmailCookieAtom = (() => {
  const key = "register_email";
  const domain = config.account.cookieDomain;

  const value = atom<RegisterEmailCookieAtom>({
    expirationDate:
      typeof window === "undefined"
        ? null
        : window.localStorage.getItem(RegisterEmailExpirationDateLocalStorageKey),
    registerEmail: Cookies.get(key) ?? null,
  });

  const wrapped = atom(
    get => get(value),
    (_get, set, newValue?: null | string) => {
      if (newValue) {
        const masked = maskValue(newValue);
        const expirationDelayInMinutes = 5;
        const expirationDate = addMinutes(new Date(), expirationDelayInMinutes);
        Cookies.set(key, masked, { domain, expires: expirationDate });
        safeSetLocalStorage(
          RegisterEmailExpirationDateLocalStorageKey,
          expirationDate.toISOString(),
        );
        set(value, {
          expirationDate: expirationDate.toISOString(),
          registerEmail: masked,
        });
      } else {
        Cookies.remove(key, { domain });
        typeof window !== "undefined" &&
          window.localStorage.removeItem(RegisterEmailExpirationDateLocalStorageKey);
        set(value, {
          expirationDate: null,
          registerEmail: null,
        });
      }
    },
  );
  return wrapped;
})();
