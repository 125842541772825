/* stylelint-disable block-no-empty */
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { theme } from "@product/scmp-sdk";

export type Breakpoint = (typeof theme.breakpoints.keys)[number];
type BreakpointUpKey = `${Breakpoint}Up`;
export type RequiredBreakpointKey = Extract<BreakpointUpKey, "desktopUp" | "mobileUp" | "tabletUp">;
export type OptionalBreakpointKey = Exclude<BreakpointUpKey, RequiredBreakpointKey>;
export type ResponsiveVariants<T> = Partial<Record<OptionalBreakpointKey, T>> &
  Record<RequiredBreakpointKey, T>;
type VariantStyleFunction<T> = (variant: T) => null | SerializedStyles;

export function setResponsiveVariantStyles<T>(
  responsiveVariants: ResponsiveVariants<T>,
  computeStylesFromVariant: VariantStyleFunction<T>,
) {
  return css`
    ${theme.breakpoints.up("mobile")} {
      ${computeStylesFromVariant(responsiveVariants.mobileUp)}
    }
    ${theme.breakpoints.up("tablet")} {
      ${computeStylesFromVariant(responsiveVariants.tabletUp)}
    }
    ${theme.breakpoints.up("desktop")} {
      ${responsiveVariants.desktopUp && computeStylesFromVariant(responsiveVariants.desktopUp)}
    }
    ${theme.breakpoints.up("mediumDesktop")} {
      ${responsiveVariants.mediumDesktopUp &&
      computeStylesFromVariant(responsiveVariants.mediumDesktopUp)}
    }
    ${theme.breakpoints.up("largeDesktop")} {
      ${responsiveVariants.largeDesktopUp &&
      computeStylesFromVariant(responsiveVariants.largeDesktopUp)}
    }
    ${theme.breakpoints.up("xLargeDesktop")} {
      ${responsiveVariants.xLargeDesktopUp &&
      computeStylesFromVariant(responsiveVariants.xLargeDesktopUp)}
    }
  `;
}
