import { notEmpty } from "@product/scmp-sdk";
import last from "lodash/last";

import { isStyleSectionContent } from "scmp-app/components/section/section-style/helpers";

export const getSectionRelatedSectionsQueueName = (fullSectionPath: string[]) => {
  if (fullSectionPath.length === 0) return;

  const getMagazinesStyleSubSectionRelatedQueueName = () => {
    if (fullSectionPath.length !== 4) return;
    if (!isStyleSectionContent(fullSectionPath)) return;

    return `section_categories_${fullSectionPath[2]}`;
  };

  return (
    getMagazinesStyleSubSectionRelatedQueueName() ?? `section_categories_${last(fullSectionPath)}`
  );
};

export const getSectionNameSlug = (name: string) => {
  const sectionNameSlug = name
    .toLowerCase()
    .replaceAll(/\W/g, " ")
    .split(" ")
    .filter(notEmpty)
    .join("_");

  switch (sectionNameSlug) {
    case "paris_olympics_2024":
      return "sport";
    case "this_week_in_asia":
      return "asia";
    default:
      return sectionNameSlug;
  }
};
