/**
 * @generated SignedSource<<6129fd4d8b51c3b457c777d1d15c8e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersAppBarVariantBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLiveArticle" | "helpersCheckIsPlusPage" | "helpersCheckIsPostMagazineArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsPostiesPage" | "helpersCheckIsStyleArticle" | "helpersSectionContent" | "helpersStyleSectionContent">;
  readonly " $fragmentType": "helpersAppBarVariantBase";
};
export type helpersAppBarVariantBase$key = {
  readonly " $data"?: helpersAppBarVariantBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarVariantBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersAppBarVariantBase"
};

(node as any).hash = "53fcc91aa2dd230145ba0b496f5b2ec8";

export default node;
