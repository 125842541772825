import type { FunctionComponent, SuspenseProps } from "react";
import { Suspense, useEffect, useState } from "react";

/**
 * This component will render the suspense in CSR for handling suspense boundary issue
 */
export const ClientSideSuspense: FunctionComponent<SuspenseProps> = ({ children, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return show ? <Suspense {...props}>{children}</Suspense> : props.fallback;
};

ClientSideSuspense.displayName = "ClientSideSuspense";
