import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext } from "react";

import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { CustomQueryParameters } from "scmp-app/types/gtm";

// TODO: use base link context for replacing all customQueryParameters in props
type ContextValue = {
  customQueryParameters?: CustomQueryParameters;
};

type Props = {
  children: ReactNode;
  customQueryParameters?: Partial<CustomQueryParameters> & Pick<CustomQueryParameters, "module">;
};

const BaseLinkContext = createContext<ContextValue>({
  customQueryParameters: undefined,
});

export const BaseLinkContextProvider: FunctionComponent<Props> = ({
  children,
  customQueryParameters,
}) => {
  const pageType = useCurrentPageType();

  return (
    <BaseLinkContext.Provider
      value={{
        customQueryParameters: {
          pgtype: pageType,
          ...customQueryParameters,
        },
      }}
    >
      {children}
    </BaseLinkContext.Provider>
  );
};

export const useBaseLinkContext = () => useContext(BaseLinkContext);
