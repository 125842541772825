import { PageType } from "@product/scmp-sdk";

export const pathname = "/plus";

export const page = {
  route(
    query: QueryParameters<{
      articleId?: string;
      display?: string;
      nodeId?: string;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Plus },
    };
  },
};
