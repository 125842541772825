import Script from "next/script";
import { type FunctionComponent } from "react";

import { useGoogleFc } from "scmp-app/components/advertisement/funding-choices/hooks";

export const GooglePublisherTagScripts: FunctionComponent = () => {
  useGoogleFc();

  return (
    <Script
      id="google-publisher-tag"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="afterInteractive"
    />
  );
};

GooglePublisherTagScripts.displayName = "GooglePublisherTagScripts";
