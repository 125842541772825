import { areArraysEqual } from "scmp-app/components/section/helpers";
import { section as sectionData } from "scmp-app/data";

import { PostMagazineMainSectionPath } from "./const";

export const isPostMagazineSectionContent = (entityIds: string[]) =>
  entityIds?.at(0) === sectionData.postMagazine.entityId;

export const isPostMagazineMainSectionBySectionPath = (sectionPath: string[]) => {
  if (sectionPath.length !== 2) return false;
  return areArraysEqual(sectionPath, PostMagazineMainSectionPath);
};
