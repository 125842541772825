import type { PageProps as NextPageProps } from "next";

import {
  createBaseClientEnvironment,
  createClientEnvironment,
} from "scmp-app/lib/relay/environment.client";

export type PageProps = {
  isUseStaticProps: true;
} & NextPageProps;

export const createClientEnvironmentByPageProps = (pageProps: PageProps) =>
  // TODO: workaround for using getStaticProps in 404 page
  pageProps.isUseStaticProps ? createBaseClientEnvironment() : createClientEnvironment();
