import type { section } from "scmp-app/data";

import type { DefaultComponentsProvider } from ".";

export type ArticleRenderChildProps = {
  isInlinePaywallDomChanged?: boolean;
  isShowInlinePaywall?: boolean;
  provider: DefaultComponentsProvider;
};

export type MagazineVariant =
  | typeof section.postMagazine.entityId
  | typeof section.thisWeekInAsia.entityId;

export enum ArticleRenderType {
  Generic = "generic",
  Infographic = "infographic",
  InfographicLongRead = "infographic-long-read",
  Live = "live",
  LongRead = "long-read",
  MorningStudio = "morning-studio",
  Newsletter = "newsletter",
  PhotoEssay = "photo-essay",
  Plus = "plus",
  Posties = "posties",
  Series = "series",
  Style = "style",
}
