import { graphql, readInlineData } from "react-relay";

import type { helpersCheckIsPostiesPage$key } from "scmp-app/queries/__generated__/helpersCheckIsPostiesPage.graphql";

export const checkIsPostiesPage = (reference: helpersCheckIsPostiesPage$key) => {
  const data = readInlineData(
    graphql`
      fragment helpersCheckIsPostiesPage on Page @inline {
        urlAlias
      }
    `,
    reference,
  );

  return data?.urlAlias?.includes("/posties");
};
