import { PageType } from "@product/scmp-sdk";

export const pathname = "/posties/section-index";

export const page = {
  route(query: QueryParameters<{ entityId: string; name: string }>) {
    return {
      pathname,
      query: { ...query, pageType: PageType.PostiesSection },
    };
  },
};
