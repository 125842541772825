import { notEmpty, scmpPlus } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import {
  checkArticleIsInSections,
  getArticleType,
} from "scmp-app/components/article/article-render/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { section as sectionData } from "scmp-app/data";
import { article as articleData } from "scmp-app/data/article";
import type { helpersCheckCanShowInPlusPlatformArticle$key } from "scmp-app/queries/__generated__/helpersCheckCanShowInPlusPlatformArticle.graphql";
import type { helpersCheckIsPlusArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsPlusArticle.graphql";

export const checkIsPlusArticle = (reference: helpersCheckIsPlusArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPlusArticle on Article @inline {
        paywallTypes {
          entityUuid
        }
      }
    `,
    reference,
  );

  const paywallTypes = article?.paywallTypes?.map(paywallType => paywallType?.entityUuid);

  return notEmpty(paywallTypes?.includes(scmpPlus.article.paywallTypeId));
};

export const checkIsContainsPlusQueryString = (
  query: Record<string, string | string[] | undefined>,
) => {
  const { articleId, display } = query;
  if (notEmpty(articleId) && display === "plus") {
    return true;
  }
};

export const checkCanShowInPlusPlatform = (
  reference: helpersCheckCanShowInPlusPlatformArticle$key,
) => {
  const allowedInPlusPlatformTypes = new Set<string>([
    articleData.types.column.entityId,
    articleData.types.dailyPulseBriefing.entityId,
    articleData.types.factSheet.entityId,
    articleData.types.opinion.entityId,
  ]);

  const article = readInlineData(
    graphql`
      fragment helpersCheckCanShowInPlusPlatformArticle on Article @inline {
        ...helpersCheckArticleIsInSectionsArticle
        ...helpersCheckIsPlusArticle
        ...helpersCheckIsStyleArticle
        ...helpersGetArticleTypeArticle
        ...helpersUseContentSponsorContent
      }
    `,
    reference,
  );

  if (checkIsPlusArticle(article)) return true;
  if (
    checkIsStyleArticle(article) ||
    checkArticleIsInSections(article, [sectionData.posties.entityId, sectionData.yp.entityId]) ||
    notEmpty(getContentSponsor(article)?.name)
  )
    return false;

  const articleType = getArticleType(article);
  const isAllowedTypes = articleType === undefined || allowedInPlusPlatformTypes.has(articleType);

  return isAllowedTypes;
};
