import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo } from "react";

import { accountAtom } from "scmp-app/lib/account";

import LearnMoreWithoutLoginSvg from "./learn-more-without-login.svg";
import LearnMoreSvg from "./learn-more.svg";
import { Container } from "./styles";

type Props = {
  className?: string;
};

export const PlusLearnMorePlaceholder: FunctionComponent<Props> = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);

  const SvgComponent = useMemo(
    () => (isLoggedIn ? LearnMoreSvg : LearnMoreWithoutLoginSvg),
    [isLoggedIn],
  );
  return (
    <Container>
      <SvgComponent />
    </Container>
  );
};

PlusLearnMorePlaceholder.displayName = "PlusLearnMorePlaceholder";
