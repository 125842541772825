// CSpell:ignore adsystem
import Script from "next/script";
import type { FunctionComponent } from "react";

import { config } from "scmp-app/data";

export const AmazonPublisherServiceScripts: FunctionComponent = () => (
  <>
    {/* Extracted from the official init script to directly load the script instead of generate script element dynamically to improve lighthouse publisher ads score */}
    <Script
      id="amazon-publisher-service"
      src="//c.amazon-adsystem.com/aax2/apstag.js"
      strategy="afterInteractive"
    />
    {/* Configuration script */}
    <Script
      dangerouslySetInnerHTML={{
        __html: `
            apstag.init({
              adServer: "googletag",
              bidTimeout: ${config.advertisement.amazonPublisherService.bidTimeout},
              pubID: "${config.advertisement.amazonPublisherService.publisherId}",
              simplerGPT: true,
            });
          `,
      }}
      id="amazon-publisher-service-config"
      strategy="afterInteractive"
    />
  </>
);

AmazonPublisherServiceScripts.displayName = "AmazonPublisherServiceScripts";
