import capitalize from "lodash/capitalize";

const urlRegex = new RegExp("^(?:[a-z]+:)?//", "i");
export function isAbsoluteUrl(data: string) {
  return urlRegex.test(data);
}

export function isString(data: unknown): data is string {
  return typeof data === "string";
}

export function isNumeric(data: unknown): data is number {
  return typeof data === "number";
}

export function isValidUrl(data: string) {
  try {
    new URL(data);
    return true;
  } catch {
    return false;
  }
}

export function typedCapitalize<T extends string>(value: T): Capitalize<T> {
  return capitalize(value) as Capitalize<T>;
}
