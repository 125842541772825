import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { googleFundingChoicesAtom } from "./atoms";

declare global {
  interface Window {
    googlefc?: {
      callbackQueue: Array<{
        CONSENT_DATA_READY: () => void;
      }>;
    };
  }
}

export const useGoogleFc = () => {
  const setGoogleFundingChoicesTag = useSetAtom(googleFundingChoicesAtom);
  useEffect(() => {
    window.googlefc?.callbackQueue.push({
      CONSENT_DATA_READY: () => {
        setGoogleFundingChoicesTag({
          isReady: true,
        });
      },
    });
  }, [setGoogleFundingChoicesTag]);
};
