import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  display: flex;
  justify-content: center;

  min-block-size: 39px;
  padding-block: 12px;
  padding-inline-start: 20px;

  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
  border-block-end: 1px solid #cccccc;

  ${setFullViewportWidthStyles()}

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline-start: 32px;
  }
`;
