import { notEmpty } from "@product/scmp-sdk";
import { useMountEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { accountAtom } from "scmp-app/lib/account";

import type {
  Event,
  ExperimentState,
  GeneralEvent,
  OptimizelyGetAPI,
  OptimizelyPushAPI,
  OptimizelyPushEvent,
} from "./types";

type ContextValue = {
  activeExperimentStates?: Record<string, ExperimentState>;
  events?: Record<string, Event>;
  fireEvent?: (event: Omit<GeneralEvent, "type">) => void;
};

const OptimizelyContext = createContext<ContextValue>({});

type Props = {
  children: ReactNode;
};

export const OptimizelyContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const { isLoggedIn, user } = useAtomValue(accountAtom);

  const [events, setEvents] = useState<Record<string, Event>>({});
  const [activeExperimentStates, setActiveExperimentStates] = useState<
    Record<string, ExperimentState>
  >({});

  const refreshStates = useCallback(() => {
    setEvents(window.optimizely.get("data").events);
    setActiveExperimentStates(
      window.optimizely.get("state").getExperimentStates({ isActive: true }),
    );
  }, []);

  useMountEffect(() => {
    const listeners: OptimizelyPushEvent[] = [
      {
        filter: { name: "initialized", type: "lifecycle" },
        handler: () => {
          setInitialized(true);
          refreshStates();
        },
        listener: "lifecycle.initialized",
        type: "addListener",
      },
      {
        filter: { name: "activated", type: "lifecycle" },
        handler: refreshStates,
        listener: "lifecycle.activated",
        type: "addListener",
      },
      {
        filter: { name: "pageActivated", type: "lifecycle" },
        handler: refreshStates,
        listener: "lifecycle.pageActivated",
        type: "addListener",
      },
      {
        filter: { name: "pageDeactivated", type: "lifecycle" },
        handler: refreshStates,
        listener: "lifecycle.pageDeactivated",
        type: "addListener",
      },
      {
        filter: { name: "campaignDecided", type: "lifecycle" },
        handler: refreshStates,
        listener: "lifecycle.campaignDecided",
        type: "addListener",
      },
    ];
    listeners.forEach(listener => window.optimizely.push(listener));
  });

  useEffect(() => {
    if (!initialized || !isLoggedIn || !notEmpty(user)) return;
    window.optimizely.push({
      attributes: {
        level: [...(user.lvl ?? [])].sort().join(",") ?? null,
        source: user.source,
      },
      type: "user",
    });
  }, [initialized, isLoggedIn, user]);

  const fireEvent = useCallback(
    (event: Omit<GeneralEvent, "type">) => {
      if (Object.values(events).every(event_ => event_.apiName !== event.eventName)) {
        console.warn(`Event "${event.eventName}" is not defined in Optimizely.`);
        return;
      }
      window.optimizely.push({
        type: "event",
        ...event,
      });
    },
    [events],
  );

  return (
    <OptimizelyContext.Provider
      value={{
        activeExperimentStates,
        events,
        fireEvent,
      }}
    >
      {children}
    </OptimizelyContext.Provider>
  );
};

export const useOptimizelyContext = () => useContext(OptimizelyContext);

declare global {
  interface Window {
    optimizely: {
      get: OptimizelyGetAPI;
      push: OptimizelyPushAPI;
    };
  }
}
