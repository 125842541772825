/**
 * @generated SignedSource<<a7bae1e7542c0ed2c489e4e3d037352f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPlusArticle$data = {
  readonly paywallTypes: ReadonlyArray<{
    readonly entityUuid: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsPlusArticle";
};
export type helpersCheckIsPlusArticle$key = {
  readonly " $data"?: helpersCheckIsPlusArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPlusArticle"
};

(node as any).hash = "f6ab610e6aca475aef8964828efd935f";

export default node;
