import { PageType } from "@product/scmp-sdk";

export const pathname = "/story-card";

export const styleStaticPathname = "/story/style";

export const page = {
  route(
    query: QueryParameters<{
      subSection: string;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.StoryCard },
    };
  },
};
