import { useRouter } from "next/router";
import { type FunctionComponent } from "react";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { parseQueryString } from "scmp-app/lib/utils";

import { Container } from "./styles";
import { TrendingNavBarSwiper } from "./trending-nav-bar-swiper";
type Props = {
  className?: string;
};

export const TrendingNavBar: FunctionComponent<Props> = ({ className }) => {
  const { query } = useRouter();
  const edition = parseQueryString(query.edition) ?? "int";
  return (
    <Container className={className}>
      {edition && (
        <ClientSideSuspense>
          <TrendingNavBarSwiper edition={edition} />{" "}
        </ClientSideSuspense>
      )}
    </Container>
  );
};

TrendingNavBar.displayName = "TrendingNavBar";
