/**
 * @generated SignedSource<<48d0dc0b08b0ebadb57a2b4f3ed5436b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckHasLiveTagArticle$data = {
  readonly flattenTypeIds: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly liveArticle: {
    readonly status: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "helpersCheckHasLiveTagArticle";
};
export type helpersCheckHasLiveTagArticle$key = {
  readonly " $data"?: helpersCheckHasLiveTagArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckHasLiveTagArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckHasLiveTagArticle"
};

(node as any).hash = "f5b96a99fccb22568477ef81766f8fc3";

export default node;
