/**
 * @generated SignedSource<<3ee5ae3a1714e9e6e5b28819f653e618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersSanitizeArticleTypeEntityIdsArticle$data = {
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersSanitizeArticleTypeEntityIdsArticle";
};
export type helpersSanitizeArticleTypeEntityIdsArticle$key = {
  readonly " $data"?: helpersSanitizeArticleTypeEntityIdsArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSanitizeArticleTypeEntityIdsArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersSanitizeArticleTypeEntityIdsArticle"
};

(node as any).hash = "beeeb6c79e260b11456072aedc2307fa";

export default node;
