import { config } from "./configs";

export { application } from "./application";
export { acknowledgementGateArticleIds, article, starlingArticleEntityIds } from "./article";
export { author, authorTypes } from "./author";
export { config } from "./configs";
export { newsletter } from "./newsletter";
export { paywall } from "./paywall";
export { section } from "./section";
export { topic } from "./topic";
export * as tracking from "./tracking";

export const data = {
  application: {
    goldthread: { domain: "www.goldthread2.com", entityId: "327250" },
    scmp: {
      domain: config.domain.scmp,
      entityId: "325477",
      entityUuid: "2695b2c9-96ef-4fe4-96f8-ba20d0a020b3",
    },
  },
  scmpDomains: {
    ABACUS: "abacusnews.com",
    GOLDTHREAD: "goldthread2.com",
    SCMP: "scmp.com",
    SCMP_STAGING: "scmp.tech",
  },
  topicList: {
    breaking_news: "Breaking",
    china: "China",
    coronavirus: "Coronavirus",
    hong_kong: "Hong Kong",
    scmp_exclusive: "SCMP Exclusive",
  } as Record<string, string>,
  youtube: {
    /* cspell: disable-next-line */
    mmaChannelId: "UCivB3CVSWoD5GEz6kTv2bGQ",
    /* cspell: disable-next-line */
    scmpChannelId: "UC4SUWizzKc1tptprBkWjX2Q",
    /* cspell: disable-next-line */
    styleChannelId: "UCtYYXR3QV_1mKdJNksfCRtQ",
  },
} as const;
